<template>
  <div class="page_feedback right_connent" ref="feedback">
    <div class="title">意见反馈</div>
    <div class="connent_wrap">
      <div class="top">
        <div class="title_2">请选择反馈板块<i>*</i></div>
        <div class="label">
          <div
            :class="{ on: check_type == item.name }"
            class="item_label"
            v-for="(item, index) in label_list"
            :key="index"
            @click="change_label(item.name)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="center">
        <div class="title_2">问题截图</div>
        <div class="up_wrap">
          <template v-for="(item, index) in feedback_img">
            <div class="img_box" :key="index">
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                fit="contain"
                @click="clickBig(item)"
              ></el-image>
              <div class="close" @click="closeImg(index)">
                <i class="el-icon-close" color="#e2777a"></i>
              </div>
            </div>
          </template>
          <div
            v-if="feedback_img.length < 9"
            class="add_btn"
            @click="uploadUserLogo()"
          >
            <img src="@/assets/mine/add_fe.png" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="title_2">意见和问题 <i>*</i></div>
        <div class="last">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请填写您的意见或问题反馈~感谢您的支持！"
            v-model="textarea_value"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <div class="btn_su" @click="sub_feedback">提交反馈</div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { host } from "@/config/config.js";
import { randomWord } from "@/assets/common/js/method.js";
var COS = require("cos-js-sdk-v5");
var Bucket = "successkaoyan-1254014383";
var Region = "ap-beijing"; /* 存储桶所在地域，必须字段 */

// 初始化实例
var cos = new COS({
  Domain: "success-image.successkaoyan.com",
  Protocol: "https:",
  getAuthorization: function (options, callback) {
    var url = host + "cos_s";
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.onload = function (e) {
      //                // console.log(e);
      try {
        var data = JSON.parse(e.target.responseText);
      } catch (e) {}
      callback({
        TmpSecretId: data.credentials && data.credentials.tmpSecretId,
        TmpSecretKey: data.credentials && data.credentials.tmpSecretKey,
        XCosSecurityToken: data.credentials && data.credentials.sessionToken,
        ExpiredTime: data.expiredTime,
      });
    };
    xhr.send();
  },
});
//获取年月日
var dateimg = new Date();
var yearimg = dateimg.getFullYear() + "";
var monthimg = dateimg.getMonth() + 1 + "";
var dayimg = dateimg.getDate() + "";
var simg = dateimg.getTime() + "";
if (monthimg < 10) {
  monthimg = "0" + monthimg;
}

if (dayimg < 10) {
  dayimg = "0" + dayimg;
}
var timeimg = yearimg + monthimg + dayimg;
export default {
  //   name: "my_order",
  data() {
    return {
      label_list: [
        {
          id: 0,
          name: "院校资讯",
        },
        {
          id: 1,
          name: "社区论坛",
        },
        {
          id: 2,
          name: "教材资料",
        },
        {
          id: 3,
          name: "学习工具",
        },
        {
          id: 4,
          name: "活动课程",
        },
        {
          id: 5,
          name: "其他",
        },
      ],
      textarea_value: "",
      check_type: "",
      feedback_img: [
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501008457UCjrpgI6aixYsTu.gif",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501014127aOvaFoZ3cjPukDe.gif",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501014127xBLiKqNauhhSXqJ.gif",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501020076dEqVktKMzyVtdSK.gif",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/163850102007615UI4sWS6Ra3Uxf.gif",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501124914l76jtkeYGMsnt7a.jpg",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501143617wTgydCroNFPiU9l.win4000",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501143617l49lPIISvIxWHyP.png",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501143617DUOAMArxQAcNBtO.png",
        // "https://successkaoyan-1254014383.cos.ap-beijing.myqcloud.com/feedback/20211203/1638501725286pnumvnbspi7wkkQ.jpg",
      ],
    };
  },
  methods: {
    change_label(check_type) {
      this.check_type = check_type;
    },
    async sub_feedback() {
      if (!this.check_type) {
        this.$message({
          message: "请选择反馈板块",
          type: "warning",
        });
        return;
      }
      if (!this.textarea_value) {
        this.$message({
          message: "请填写意见和问题",
          type: "warning",
        });
        return;
      }
      var param = {
        feed_content: this.textarea_value,
        feed_type: this.check_type,
        source: 2,
      };
      if (this.feedback_img.length) {
        param.feedback_img = JSON.stringify(this.feedback_img);
      }
      // console.log(param);
      try {
        const res = await api.add_feedback(param);
        if (res.code == 0) {
          this.feedback_img = [];
          this.check_type = "";
          this.textarea_value = "";
          this.$notify({
            title: "反馈成功",
            message: "感谢您的支持 ❛‿˂̵✧",
            type: "success",
          });
        } else {
          this.$message({
            message: "反馈失败",
            type: "warning",
          });
          this.feedback_img = [];
          this.check_type = "";
          this.textarea_value = "";
        }
      } catch (error) {
        console.log(error);
      }
    },

    uploadUserLogo() {
      var u_input = document.getElementById("fileinput");
      if (!u_input) {
        var u_input = document.createElement("input");
        u_input.type = "file";
        u_input.id = "fileinput";
        u_input.style.position = "absolute";
        u_input.style.left = "-999px";
        u_input.style.top = "-999px";
        u_input.accept = "image/*";
        u_input.multiple = "multiple";
        this.$refs.feedback.appendChild(u_input);
      }

      u_input.onchange = (event) => {
        var files = event.path[0].files;
        this.uploadCos(files);
      };
      document.getElementById("fileinput").click();
    },
    uploadCos(files) {
      console.log(files);
      var files_arr = [];

      for (let i = 0; i < files.length; i++) {
        console.log(files[i], i);
        var nowTime_s = new Date().getTime();
        var random_num = randomWord(false, 15);
        // var suffix = item.name.split('.')[1]
        var index = files[i].name.lastIndexOf(".");
        var suffix = files[i].name.substr(index + 1);

        files_arr.push({
          Bucket: Bucket,
          Region: Region,
          Key:
            "feedback/" +
            timeimg +
            "/" +
            nowTime_s +
            random_num +
            "." +
            suffix /* 必须 */,
          Body: files[i],
        });
      }
      let _this = this;
      cos.uploadFiles(
        {
          files: files_arr,
          onProgress: function (info) {
            var percent = parseInt(info.percent * 10000) / 100;
            var speed = parseInt((info.speed / 1024 / 1024) * 100) / 100;
            // console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;')
          },
          onFileFinish: function (err, data, options) {
            // console.log(options.Key + '上传' + (err ? '失败' : '完成'))
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data) {
            data.files.forEach(function (item, index) {
              _this.feedback_img.push("https://" + item.data.Location);
            });
          }
        }
      );
    },
    closeImg(index) {
      this.feedback_img.splice(index, 1);
    },
  },
  components: {},
};
</script>


<style >
.page_feedback .el-textarea__inner {
  height: 134px;
}
</style>

<style lang="less" scoped>
/deep/textarea {
  resize: none;
}
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;

    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.title_2 {
  font-size: 16px;

  font-weight: bold;
  color: #333333;
  i {
    color: #f96158;
  }
}
.page_feedback {
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;

    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 2px;
    border-bottom: 1px solid #e9e9e9;
  }
  .connent_wrap {
    margin-top: 24px;
    .label {
      margin-top: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      .item_label {
        margin-right: 23px;
        line-height: 48px;
        width: 114px;
        height: 48px;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        color: #999999;
        cursor: pointer;
      }
      .item_label:last-of-type {
        margin: 0;
      }
      .on {
        color: #489cff;
        background: #f2f2ff;
      }
    }
    .center {
      .add_btn {
        width: 114px;
        height: 114px;
        // margin-top: 28px;
        cursor: pointer;
        img {
          width: 114px;
          height: 114px;
        }
      }
      margin-top: 40px;
    }
    .bottom {
      margin-top: 40px;
      .last {
        margin-top: 24px;
      }
    }
    .btn_su {
      text-align: center;
      width: 161px;
      height: 40px;
      background: #479dff;
      border-radius: 20px;
      line-height: 40px;
      font-size: 18px;

      font-weight: 500;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 55px;
      cursor: pointer;
    }
  }
}
.up_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
}
.img_box {
  position: relative;
  width: 114px;
  height: 114px;
  margin-bottom: 20px;
  margin-right: 20px;
  background: #eee;
  img {
    width: 100%;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 0 0 0 10px;
    cursor: pointer;
  }
}
</style>