import { render, staticRenderFns } from "./feedback.vue?vue&type=template&id=29b4f247&scoped=true&"
import script from "./feedback.vue?vue&type=script&lang=js&"
export * from "./feedback.vue?vue&type=script&lang=js&"
import style0 from "./feedback.vue?vue&type=style&index=0&lang=css&"
import style1 from "./feedback.vue?vue&type=style&index=1&id=29b4f247&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b4f247",
  null
  
)

export default component.exports